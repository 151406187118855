// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-do-not-sell-info-index-js": () => import("./../../../src/pages/do-not-sell-info/index.js" /* webpackChunkName: "component---src-pages-do-not-sell-info-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-spa-sc-chroma-index-js": () => import("./../../../src/pages/lp/spa-sc-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-spa-sc-chroma-index-js" */),
  "component---src-pages-lp-spa-sc-download-index-js": () => import("./../../../src/pages/lp/spa-sc-download/index.js" /* webpackChunkName: "component---src-pages-lp-spa-sc-download-index-js" */),
  "component---src-pages-lp-spa-sc-tide-index-js": () => import("./../../../src/pages/lp/spa-sc-tide/index.js" /* webpackChunkName: "component---src-pages-lp-spa-sc-tide-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-ty-thank-you-index-js": () => import("./../../../src/pages/ty/thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-thank-you-index-js" */),
  "component---src-pages-uninstall-index-js": () => import("./../../../src/pages/uninstall/index.js" /* webpackChunkName: "component---src-pages-uninstall-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */)
}

